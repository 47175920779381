import React from 'react';
import { IconButton } from '@mui/material';
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import { colors } from '../utils/constants';

const BackButton = ({ navigate }) => (
  <IconButton
    size="large"
    sx={{
      backgroundColor: colors['main-color-cl-01'],
      '&:hover': {
        backgroundColor: colors['main-color-cl-01-hover'],
      },
    }}
    onClick={navigate}
  >
    <ArrowBackIosRoundedIcon sx={{ color: colors['main-color-cl-19'] }} />
  </IconButton>
);

export { BackButton };
