import React, { useEffect } from 'react';
import { useDocumentTitle } from 'paul-core-package';
import { useMsal } from '@azure/msal-react';
import styles from '../styles/components/Login.module.css';
import { loginRequest } from '../utils/auth';

const Login = () => {
  useDocumentTitle('Paul - Login');

  const { instance } = useMsal();

  /** @todo Redevelop this part with async-await approach */
  useEffect(() => {
    instance
      .handleRedirectPromise()
      .then((tokenResponse) => {
        if (!tokenResponse) {
          const accounts = instance.getAllAccounts();
          if (accounts.length === 0) {
            instance.loginRedirect(loginRequest);
          }
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, [instance]);

  return <div className={styles.login_wrap} />;
};

export default Login;
