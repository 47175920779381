import React, { useEffect } from 'react';
import './styles/main.css';
import { CoreWrap, useThemeDetector } from 'paul-core-package';
import { LicenseInfo } from '@mui/x-license-pro';
import Routing from './Routing';
import * as auth from './utils/auth';
import { Login } from './components';
import { listOfBaseUrls } from './utils/constants';

const App = () => {
  LicenseInfo.setLicenseKey(
    'f8370a1770dde0be2e56bad6f23f4a00Tz04MzA3MyxFPTE3Mzc5Nzk2MjkwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=',
  );
  const isDarkTheme = useThemeDetector();

  useEffect(() => {
    if (isDarkTheme)
      document.querySelector(
        "link[rel='icon shortcut']",
      ).href = require('./images/favicons/favicon-32x32-Dark.png');
    else
      document.querySelector(
        "link[rel='icon shortcut']",
      ).href = require('./images/favicons/favicon-32x32-Light.png');
  }, [isDarkTheme]);

  return (
    <CoreWrap authFile={auth} urls={listOfBaseUrls} loginComponent={<Login />}>
      <Routing />
    </CoreWrap>
  );
};

export default App;
