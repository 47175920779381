import React from 'react';

const BuildingsSVG = ({ width = 24, height = 24, color = '#98A2B3' }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.70711 8.29289C8.31658 7.90237 7.68342 7.90237 7.29289 8.29289C6.90237 8.68342 6.90237 9.31658 7.29289 9.70711L8.70711 8.29289ZM13 14H14C14 13.7348 13.8946 13.4804 13.7071 13.2929L13 14ZM13 21V22C13.5523 22 14 21.5523 14 21H13ZM8 21H7C7 21.5523 7.44772 22 8 22V21ZM9 17C9 16.4477 8.55228 16 8 16C7.44772 16 7 16.4477 7 17H9ZM8 22C8.55228 22 9 21.5523 9 21C9 20.4477 8.55228 20 8 20V22ZM3 21H2C2 21.5523 2.44772 22 3 22V21ZM3 14L2.29289 13.2929C2.10536 13.4804 2 13.7348 2 14H3ZM8.70711 9.70711C9.09763 9.31658 9.09763 8.68342 8.70711 8.29289C8.31658 7.90237 7.68342 7.90237 7.29289 8.29289L8.70711 9.70711ZM8 10C8 10.5523 8.44772 11 9 11C9.55228 11 10 10.5523 10 10H8ZM21 21V22C21.5523 22 22 21.5523 22 21H21ZM13 20C12.4477 20 12 20.4477 12 21C12 21.5523 12.4477 22 13 22V20ZM7.29289 9.70711L12.2929 14.7071L13.7071 13.2929L8.70711 8.29289L7.29289 9.70711ZM12 14V21H14V14H12ZM13 20H8V22H13V20ZM9 21V17H7V21H9ZM8 20H3V22H8V20ZM4 21V14H2V21H4ZM3.70711 14.7071L8.70711 9.70711L7.29289 8.29289L2.29289 13.2929L3.70711 14.7071ZM10 10V4H8V10H10ZM10 4V2C8.89543 2 8 2.89543 8 4H10ZM10 4H20V2H10V4ZM20 4H22C22 2.89543 21.1046 2 20 2V4ZM20 4V21H22V4H20ZM21 20H13V22H21V20Z"
      fill="#98A2B3"
    />
    <path
      d="M13 7V7.01"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17 7V7.01"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17 11V11.01"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17 15V15.01"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export { BuildingsSVG };
