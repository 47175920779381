import React from 'react';
import { Button, Skeleton, Typography } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router';
import { useSelector } from 'react-redux';
import { selectors } from 'paul-core-package';
import PropTypes from 'prop-types';
import { colors } from '../../utils/constants';

const Tab = ({ routingUrl, textKey, startIcon, isDisabled = false }) => {
  const navigate = useNavigate();
  const location = useLocation();
  /** @todo: add getActiveTranslationsInterfaceLocalizations in core */
  const tI = useSelector(selectors.getActiveTranslationsInterface)?.UI?.Localizations || {};

  const getSxTab = (url) => {
    const activeUrl = url === location.pathname;
    return {
      color: activeUrl ? colors['main-color-cl-01'] : colors['main-color-cl-15'],
    };
  };
  const sxTypography = {
    fontWeight: 'bold',
  };
  return (
    <Button
      color="secondary"
      sx={getSxTab(routingUrl)}
      startIcon={startIcon}
      onClick={() => navigate(routingUrl)}
      disabled={isDisabled}
    >
      <Typography variant="button" sx={sxTypography}>
        {tI[textKey] ?? (
          <Skeleton variant="text" sx={{ fontSize: 'inherit', height: 30, width: 85 }} />
        )}
      </Typography>
    </Button>
  );
};

Tab.propTypes = {
  routingUrl: PropTypes.string.isRequired,
  textKey: PropTypes.string.isRequired,
  startIcon: PropTypes.element,
};

export { Tab };
