import React from 'react';
import { Box } from '@mui/material';
import { useParams } from 'react-router';
import { PinSVG } from '../../icons/PinSVG';
import { DocumentSVG } from '../../icons/DocumentSVG';
import { BuildingsSVG } from '../../icons/BuildingsSVG';
import { routingUrls } from '../../utils/constants';
import { Tab } from './Tab';

const ContentTabs = () => {
  const { siteId } = useParams();
  return (
    <Box
      sx={{
        display: 'flex',
        gap: 5,
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        top: 0,
        width: '100%',
        margin: '40px 0',
        pointerEvents: 'none',
        height: '90px',
        zIndex: '3',
        '> *': {
          pointerEvents: 'auto',
        },
      }}
    >
      <Tab startIcon={<PinSVG />} routingUrl={routingUrls.sites()} textKey="map" />
      <Tab
        startIcon={<BuildingsSVG />}
        routingUrl={routingUrls.details({ siteId })}
        textKey="system"
      />
      <Tab
        startIcon={<DocumentSVG />}
        routingUrl={routingUrls.documents({ siteId })}
        textKey="documents"
      />
    </Box>
  );
};

export { ContentTabs };
