import React, {useCallback, useEffect} from 'react';
import { SiteMap, siteCardGoToKey } from 'paul-map-package';
import { useNavigate } from 'react-router';
import { actions, useDocumentTitle } from 'paul-core-package';
import { useDispatch } from 'react-redux';
import { routingUrls } from '../utils/constants';

const SitesMap = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useDocumentTitle('Paul - Dashboard');

  useEffect(() => {
    dispatch(actions.setActiveSiteId(null));
  }, [dispatch]);

  const goTo = useCallback(({ siteId, type }) => {
    if (type === siteCardGoToKey.isApproved) {
      navigate(routingUrls.details({ siteId }));
      return;
    }
    navigate(routingUrls.siteStart({ siteId }));
  }, [navigate])

  return (
    <div>
      <SiteMap goTo={goTo} />
    </div>
  );
};

export default SitesMap;
