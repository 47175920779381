import React from 'react';

const DocumentSVG = ({ width = 24, height = 24, color = '#98A2B3' }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.6044 4.472C14.2956 4.168 13.8812 4 13.4506 4H7.625C6.73125 4 6 4.72 6 5.6V18.4C6 19.28 6.72312 20 7.61687 20H17.375C18.2688 20 19 19.28 19 18.4V9.464C19 9.04 18.8294 8.632 18.5206 8.336L14.6044 4.472ZM14.9375 16.8H10.0625C9.61562 16.8 9.25 16.44 9.25 16C9.25 15.56 9.61562 15.2 10.0625 15.2H14.9375C15.3844 15.2 15.75 15.56 15.75 16C15.75 16.44 15.3844 16.8 14.9375 16.8ZM14.9375 13.6H10.0625C9.61562 13.6 9.25 13.24 9.25 12.8C9.25 12.36 9.61562 12 10.0625 12H14.9375C15.3844 12 15.75 12.36 15.75 12.8C15.75 13.24 15.3844 13.6 14.9375 13.6ZM13.3125 8.8V5.2L17.7812 9.6H14.125C13.6781 9.6 13.3125 9.24 13.3125 8.8Z"
      fill={color}
    />
  </svg>
);

export { DocumentSVG };
