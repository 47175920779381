import React, { useEffect } from 'react';
import { Box, Container } from '@mui/material';
import { useNavigate, useParams } from 'react-router';
import { actions, Footer, useDocumentTitle } from 'paul-core-package';
import { SiteDocuments as SiteDocumentsContent } from 'paul-customer-documents-package';
import { useDispatch } from 'react-redux';
import { ContentTabs } from './ContentTabs';
import { BackButton } from './BackButton';
import { routingUrls } from '../utils/constants';
import styles from '../styles/components/SiteDocuments.module.css';

const SiteDocuments = () => {
  const { siteId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useDocumentTitle('Paul - Documents');

  useEffect(() => {
    dispatch(actions.setActiveSiteId(siteId));
  }, [dispatch, siteId]);

  return (
    <Box>
      <ContentTabs />
      <Container className={styles.container}>
        <Box
          sx={{
            marginTop: '170px',
            gap: 5,
            display: 'flex',
            alignItems: 'flex-start',
            flexDirection: 'column',
          }}
        >
          <BackButton navigate={() => navigate(routingUrls.details({ siteId }))} />
          <Container className={styles.container_md}>
            <Box>
              <SiteDocumentsContent />
            </Box>
            <Footer />
          </Container>
        </Box>
      </Container>
    </Box>
  );
};

export { SiteDocuments };
