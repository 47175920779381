import { useNavigate } from 'react-router';
import React, { useCallback } from 'react';
import { Box, Container } from '@mui/material';
import { SiteStart } from 'paul-map-package';
import { useDocumentTitle } from 'paul-core-package';
import styles from '../styles/components/SiteDocuments.module.css';
import { BackButton } from './BackButton';
import { routingUrls } from '../utils/constants';

export const SiteStartPage = () => {
  const navigate = useNavigate();

  useDocumentTitle('Paul - Start');

  const handleSiteApprove = useCallback(
    ({ siteId }) => {
      navigate(routingUrls.details({ siteId }));
    },
    [navigate],
  );

  return (
    <Container className={styles.container}>
      <Box sx={{ position: 'absolute', top: 0 }}>
        <SiteStart onSiteApprove={handleSiteApprove} />
      </Box>
      <Box
        sx={{
          marginTop: '170px',
          gap: 5,
          display: 'flex',
          alignItems: 'flex-start',
          flexDirection: 'column',
        }}
      >
        <BackButton navigate={() => navigate(routingUrls.sites())} />
      </Box>
    </Container>
  );
};
