import React from 'react';

const PinSVG = ({ width = 24, height = 24, color = '#98A2B3' }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_9324_349)">
      <circle
        cx="12"
        cy="11"
        r="3"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.657 16.657L13.414 20.9C12.6331 21.6801 11.3679 21.6801 10.587 20.9L6.343 16.657C3.21892 13.5328 3.21901 8.46752 6.34319 5.34339C9.46738 2.21926 14.5326 2.21926 17.6568 5.34339C20.781 8.46752 20.7811 13.5328 17.657 16.657V16.657Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_9324_349">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export { PinSVG };
