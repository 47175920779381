import React, { Suspense, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import { SiteInfo, CheckAccessToSiteKey, selectors as mapSelectors } from 'paul-map-package';
import { TemperatureCardsWidgetAsync, InfoModal } from 'paul-widget-temperature-package';
import { ComponentDetailsList } from 'paul-widget-component-details-package';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Container, Row } from 'react-bootstrap';
import { ThreeDStructure } from 'paul-webgl-3d';
import { GroupedCharts } from 'paul-charts-package';
import { Footer, actions, selectors } from 'paul-core-package';
import { ThemeConnectedEnergySaving } from 'paul-energy-saving-package';
import { Box, Skeleton, ThemeProvider, Typography, Stack } from '@mui/material';
import { ContentTabs } from './ContentTabs';
import { BackButton } from './BackButton';
import { colors, listOfBaseUrls, routingUrls, ACCESS_STATUSES } from '../utils/constants';
import styles from '../styles/components/DetailView.module.css';
import { Viewer360 } from '@paul/paul-360-viewer';
import { getThemeData, Theme } from 'paul-theme-package';

const energySavingIdToDisplayWidget = '4';
const defaultLanguage = 'en-EN';
const sourceURL = 'https://dev-paul-sharedien-image-assets.s3.eu-central-1.amazonaws.com/exports';

const DetailView = () => {
  const { siteId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const language = useSelector(selectors.selectLocales);
  const token = useSelector(selectors.selectToken);

  useEffect(() => {
    dispatch(actions.setActiveSiteId(siteId));
  }, [dispatch, siteId]);

  const { LINECHARTS_TITLE, SENSORS_TITLE } =
    useSelector(selectors.getActiveTranslationsInterfaceUILocalizations) || {};

  // TODO: move out getDataSiteById and other site selectors from paul-map-package to paul-core-package and use in the current package and in paul-map-package.
  const siteDetails = useSelector(mapSelectors.getDataSiteById(siteId));
  const hasAccessToEnergySaving =
    siteDetails?.thingStatusPAULLive?.id === energySavingIdToDisplayWidget;

  const show360Viewer = siteDetails?.thingEquirectangularMedia?.id === ACCESS_STATUSES.content;

  const handleForbidden = ({ siteId, type }) => {
    switch (type) {
      case CheckAccessToSiteKey.isForbidden: {
        navigate(routingUrls.siteStart({ siteId }));
        break;
      }
      default: {
        console.error('Site access denied');
        break;
      }
    }
  };

  return (
    <Box className={`flex-column ${styles.wrap}`}>
      <ContentTabs />
      <Container className={styles.container}>
        <Box sx={{ position: 'absolute', zIndex: 3, top: 185 }}>
          <BackButton navigate={() => navigate(routingUrls.sites())} />
        </Box>
      </Container>
      <Container fluid="lg" className={styles.container_for_3d}>
        {show360Viewer && (
          <Container className={styles.container_md}>
            <Row>
              <Col>
                <Stack flexDirection="row" justifyContent="flex-end">
                  <Box sx={{ width: 260, right: 10, top: 185, pointerEvents: 'auto' }}>
                    <ThemeProvider theme={getThemeData(Theme.LIGHT_MODERN)}>
                      <Viewer360
                        siteId={siteId}
                        apiBaseUrl={listOfBaseUrls.api}
                        sourceUrl={sourceURL}
                        staticSourceUrl={listOfBaseUrls.source}
                        language={language}
                        defaultLanguage={defaultLanguage}
                        token={token}
                      />
                    </ThemeProvider>
                  </Box>
                </Stack>
              </Col>
            </Row>
          </Container>
        )}
        <Row style={{ height: '80vh', minHeight: 700, position: 'relative' }}>
          <Suspense fallback={''}>
            <ThreeDStructure
              height="100%"
              isDebug={false}
              fillSize
              classNameWrap={styles.viewer360}
              canvasClassName={styles.canvas}
              styleWrap={{ zIndex: 2 }}
            />
          </Suspense>
        </Row>
      </Container>
      <Container fluid="md" className={styles.container_md}>
        <Row>
          <Col>
            <SiteInfo onForbidden={handleForbidden} />
            {hasAccessToEnergySaving && (
              <Box mt={-3.75}>
                <ThemeConnectedEnergySaving />
              </Box>
            )}
          </Col>
        </Row>
        <Row>
          <Col>
            <Typography
              variant="h5"
              mb={6}
              sx={{
                fontWeight: 'bold',
                color: colors['main-color-cl-01'],
              }}
            >
              {SENSORS_TITLE ?? <Skeleton variant="h5" sx={{ fontSize: 'inherit', width: 140 }} />}
            </Typography>
            <Suspense fallback={null}>
              <TemperatureCardsWidgetAsync />
            </Suspense>
            <InfoModal />
          </Col>
        </Row>
        <Row>
          <Col>
            <ComponentDetailsList />
          </Col>
        </Row>
        <Row>
          <Col>
            <Typography
              variant="h5"
              mb={6}
              sx={{
                fontWeight: 'bold',
                color: colors['main-color-cl-01'],
              }}
            >
              {LINECHARTS_TITLE ?? (
                <Skeleton variant="h5" sx={{ fontSize: 'inherit', width: 140 }} />
              )}
            </Typography>
            <GroupedCharts siteId={siteId} />
          </Col>
        </Row>
        <Footer />
      </Container>
    </Box>
  );
};

export default DetailView;
