import React from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { constants, Layout } from 'paul-core-package';
import {SitesMap, DetailView, SiteStartPage} from './components';
import { SiteDocuments } from './components/SiteDocuments';

const Routing = () => {
  const location = useLocation();

  return (
    <Layout theme={constants.themeKey.light} marginBottom="0">
      <TransitionGroup>
        <CSSTransition key={location.key} classNames="fade" timeout={250}>
          <Routes location={location}>
            <Route path="sites" element={<SitesMap />}>
              <Route path="*" element={<DetailView />} />
            </Route>
            <Route path="sites/start/:siteId" element={<SiteStartPage />} />
            <Route path="sites/details/:siteId" element={<DetailView />} />
            <Route path="sites/details/:siteId/documents" element={<SiteDocuments />} />
            <Route path="*" element={<SitesMap />} />
          </Routes>
        </CSSTransition>
      </TransitionGroup>
    </Layout>
  );
};

export default Routing;
