import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Navbar } from 'react-bootstrap';
import { constants, UserPanel } from 'paul-core-package';
import styles from '../../styles/components/Layouts/Layout.module.css';
import logoWhite from '../../images/PAUL_Logo_white.svg';
import logoBlue from '../../images/PAUL_Logo_blue.svg';

const Layout = (props) => {
  const { children, isLogin = false, theme = constants.themeKey.light } = props;

  return (
    <>
      <Navbar expand="lg" className={`py-40 ${styles.navbar} ${styles.layout}`}>
        <Container className={styles.container}>
          {!isLogin ? (
            <>
              <Navbar.Brand className="user-select-none">
                <Link to="/">
                  {/** @todo is it 2 different images. Maybe we can use the same svg and throw just color because of them?*/}
                  <img src={theme === constants.themeKey.light ? logoBlue : logoWhite} alt="PAUL" />
                </Link>
              </Navbar.Brand>
              <Navbar.Collapse className="justify-content-end">
                <Navbar.Text>
                  {/** @todo use MUI theme */}
                  <UserPanel theme={theme} />
                </Navbar.Text>
              </Navbar.Collapse>
            </>
          ) : null}
        </Container>
      </Navbar>
      <div className="">
        <main>{children}</main>
      </div>
    </>
  );
};

export default Layout;
