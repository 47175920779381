export const listOfBaseUrls = {
    api: 'https://gateway.prod.paul-cloud.de',
    source: 'https://images.prod.paul-cloud.de',
}

export const routingUrls = {
  sites: () => '/sites',
  siteStart: ({ siteId }) => `/sites/start/${siteId}`,
  details: ({ siteId }) => `/sites/details/${siteId}`,
  documents: ({ siteId }) => `/sites/details/${siteId}/documents`,
};

/** @todo: colors object should be deleted after installing MUI Theme and refactoring components to use theme colors */
export const colors = {
  'main-color-cl-01': '#00205B',
  'main-color-cl-01-hover': '#002B7A',
  'main-color-cl-02': '#2D78C6',
  'main-color-cl-03': '#37AFEB',
  'main-color-cl-04': '#81D1F7',
  'main-color-cl-05': '#BBE9FF',
  'main-color-cl-06': '#E3F7FF',
  'main-color-cl-07': '#F6F8FC',
  'main-color-cl-08': '#FFFFFF',
  'main-color-cl-09': '#F3F3F3',
  'main-color-cl-10': '#212134',
  'main-color-cl-11': '#32324D',
  'main-color-cl-12': '#4A4A6A',
  'main-color-cl-13': '#666687',
  'main-color-cl-14': '#8E8EA9',
  'main-color-cl-15': '#A5A5BA',
  'main-color-cl-16': '#C0C0CF',
  'main-color-cl-17': '#DCDCE4',
  'main-color-cl-18': '#EAEAEF',
  'main-color-cl-19': '#F6F6F9',
  'main-color-cl-20': '#00BC4B',
  'main-color-cl-21': '#D02B20',
  'main-color-cl-22': '#F29D41',
  'main-color-cl-30': '#0000FF',
  'main-color-cl-31': '#1CE783',
  'main-color-cl-32': '#EFEFED',
  'main-color-cl-40': '#E5E5E7',
  'main-color-cl-90': 'rgba(#FF00FF, 0.3)',
  'main-color-cl-91': '#FF00FF',
  'main-color-cl-92': '#FCFCFD',
  'main-color-cl-93': '#FF8000',
  'main-color-cl-94': '#E71D1C',
  'main-color-cl-95': '#101828',
  'main-color-cl-96': '#000033',
  'main-color-cl-97': '#000066',
  'main-color-cl-98': '#475467',
  'main-color-cl-99': '#667085',
  'main-color-cl-100': '#98a2b3',
};

export const ACCESS_STATUSES = {
  demo: '1',
  ordered: '2',
  content: '3',
};
